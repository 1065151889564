import { Item } from 'store/purchaseItems/types';
import { MonacofitCatgories, MonacofitLocales } from 'types';

export enum StateActions {
  Update = 'UPDATE',
  Edit = 'EDIT',
  Remove = 'REMOVE',
}

interface RefundReasons {
  admin: Array<string>;
  early_warning: Array<string>;
}

interface TerminateReasons {
  admin: Array<string>;
}

interface UserSupportInteractionChannels {
  admin: Array<string>;
}

interface UserSupportInteractionReasons {
  admin: Array<string>;
}

export interface Roles {
  payments: boolean;
  developer: boolean;
  consultant: boolean;
  telemarketer: boolean;
  telemarketing_level: string;
}

export interface RetentionTemplateAction {
  [key: string]: string;
}

export interface CommonData {
  categories: Array<MonacofitCatgories>;
  locales: Array<MonacofitLocales>;
  refund_reasons: RefundReasons;
  terminate_reasons: TerminateReasons;
  payment_provider_error_types: string[];
  user_support_interaction_channels: UserSupportInteractionChannels;
  user_support_interaction_reasons: UserSupportInteractionReasons;
  items: Item[];
  retention_template_actions: RetentionTemplateAction[];
  benefits: string[];
  roles: Roles;
}

export interface CommonDataState extends CommonData {
  isLoading: boolean;
  hasError: boolean;
  hasLoaded: boolean;
}

export const COMMON_DATA_LOAD = 'COMMON_DATA_LOAD';
export const COMMON_DATA_SUCCESS = 'COMMON_DATA_SUCCESS';
export const COMMON_DATA_ERROR = 'COMMON_DATA_ERROR';

interface CommonDataLoad {
  type: typeof COMMON_DATA_LOAD;
}

interface CommonDataSuccess {
  type: typeof COMMON_DATA_SUCCESS;
  payload: CommonData;
}

interface CommonDataError {
  type: typeof COMMON_DATA_ERROR;
}

export type CommonDataActionTypes =
  | CommonDataLoad
  | CommonDataSuccess
  | CommonDataError;
