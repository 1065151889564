export type Dispute = {
  direct_login_url: string;
  created_at: string;
  user: {
    name: string;
    country: string;
    origin_country: string;
    locale: string;
    email: string;
    phone: string;
    created_at: string;
  };
  terms_of_service: TermsOfService[];
  registration_emails: RegistrationEmail[];
  purchases: Purchase[];
  login_events: LoginEvent;
  communication: Communication;
};

export type UserAgentDetails = {
  user_agent: string | null;
  device: string;
  browser: string;
  browser_version: string;
};

interface TermsOfService {
  type: string;
  accepted_at: string;
  ip: string;
}

interface RegistrationEmail {
  recipient: string;
  created_at: string;
  content: RegistrationEmailContent;
}

interface RegistrationEmailContent {
  from: RegistrationEmailSender;
  subject: string;
  body: string;
}

interface RegistrationEmailSender {
  [key: string]: string | number;
}

interface Purchase {
  id: string;
  status: string;
  payment_method: string;
  ip: string;
  price: string;
  transaction_id: string;
  currency: string;
  purchase_item_name: string;
  paid_at: string;
  app_name: string;
  user_agent_details: UserAgentDetails;
}

interface LoginEvent {
  last_sign_in_at: string;
  events: string[];
}

interface Communication {
  count: string;
  messages: Message[];
}

interface Message {
  content: string;
  name: string;
  created_at: string;
}

export type DisputeState = {
  isLoading: boolean;
  hasError: boolean;
  dispute?: Dispute;
};

export const DISPUTES_LOAD = 'DISPUTES_LOAD';
export const DISPUTES_SUCCESS = 'DISPUTES_SUCCESS';
export const DISPUTES_ERROR = 'DISPUTES_ERROR';
interface DisputesLoad {
  type: typeof DISPUTES_LOAD;
}

interface DisputesSuccess {
  type: typeof DISPUTES_SUCCESS;
  payload: Dispute;
}

interface DisputesError {
  type: typeof DISPUTES_ERROR;
}

export type DisputesActionType = DisputesLoad | DisputesSuccess | DisputesError;
