import * as t from './types';

export const initialState: t.CommonDataState = {
  hasLoaded: false,
  isLoading: false,
  hasError: false,
  categories: [],
  locales: [],
  refund_reasons: {
    admin: [],
    early_warning: [],
  },
  terminate_reasons: {
    admin: [],
  },
  payment_provider_error_types: [],
  user_support_interaction_channels: {
    admin: [],
  },
  user_support_interaction_reasons: {
    admin: [],
  },
  items: [],
  retention_template_actions: [],
  benefits: [],
  roles: {
    payments: false,
    developer: false,
    consultant: false,
    telemarketer: false,
    telemarketing_level: '',
  },
};

const commonDataReducer = (
  state = initialState,
  action: t.CommonDataActionTypes
): t.CommonDataState => {
  switch (action.type) {
    case t.COMMON_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
        hasLoaded: false,
      };

    case t.COMMON_DATA_SUCCESS:
      return {
        isLoading: false,
        hasError: false,
        hasLoaded: true,
        ...action.payload,
      };

    case t.COMMON_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        hasLoaded: false,
      };

    default:
      return {
        ...state,
      };
  }
};

export default commonDataReducer;
